

.nowrap {
    white-space: nowrap;
}

.text-center {
    text-align: center;
}

.mb-3 {
    margin-bottom: 1.8em;
}


.popIn {
    display: block;
    @keyframes popIn {
        0% {
            transform: scale(0.7);
            opacity: 0;
        }
        100% {
            transform: scale(1);
            opacity: 1;
        }
    }
    animation: popIn 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
    // &.slow {
    //     animation-duration: 0.9s;
    // }
}
