
.loader {
    border: 0.3em solid #fff;
    border-radius: 50%;
    border-top: 0.3em solid $main;
    width: 3em;
    height: 3em;
    -webkit-animation: spin 500ms linear infinite; /* Safari */
    animation: spin 500ms linear infinite;
    display: inline-block;
    margin: -1em 1em;
    &.is-hidden {
        display: none;
    }
  }
  
  /* Safari */
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }