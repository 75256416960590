.field-wrap {
    label {
        display: block;
        margin: 0 0 0.5em;
        font-weight: 700;
        font-size: 0.8em;
    }
    .input-wrap {
        input,
        textarea {
            background: none;
            width: 100%;
            display: block;
            padding: 0.5em 0.8em;
            border: $darkhr 1px solid;
            font-size: 1em;
            color: white;
            border-radius: 0.1em;
            box-sizing: border-box;
            &::placeholder {
                color: $icontext;
            }
            &:focus {
                outline: none;
                border-color: $yellow;
            }
        }
        textarea {
            overflow: hidden !important;
            min-height: 3.8em;
        }
        &.flex {
            display: flex;
            margin: 0 0 0.3em;
            font-size: 0.8em;
            button {
                border-radius: 0 0.3em 0.3em 0;
                white-space: nowrap;
                // padding: 0.6em 1em;
            }
            input {
                border-radius: 0.3em 0 0 0.3em;
                font-size: 0.8em;
                padding: 0;
                text-indent: 0.8em;
                direction: ltr;
            }
        }
    }
}

.text-flex {
    display: flex;
    margin: 0 -0.1em;
    margin-top: 1em;
    .button {
        text-align: center;
        flex-grow: 1;
    }
    .seperator {
        display: block;
        width: 1em;
    }
}

textarea,
input[type="text"],
input[type="email"] {

    background: none;
    border: 0.1em $border solid;
    padding: 0.7em 0.8em;
    display: block;
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 0.3em;
    border-radius: 0.3em;

    &.blink-input {
        &:not(:focus) {
            // border-color: white;
            // border: $darkhr 1px solid;
            @keyframes blinkBorder {
                0% {
                    // transform: scale(1);
                    // opacity: 0.7;
                    box-shadow: 0 0 0 0 $darkhr;
                }
                50% {
                    box-shadow: 0 0 0.5em -0.2em $darkhr;
                    // transform: scale(1.7);
                    // opacity: 0;
                    border-color: $darkhr;
                }
                100% {
                    box-shadow: 0 0 1.2em -0.2em $yellow;
                    border-color: $yellow;
                    // transform: scale(1.7);
                    // opacity: 0;
                }
            }
            animation: blinkBorder 1.2s infinite alternate ease-in-out;
        }
    }
}
