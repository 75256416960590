
$yellow: #febf6d;
$orange: #fc9c59;
$main: rgba(251, 114, 64, 1);
$red: #ff5f6b;
$color__text-main: #222;



$white: #fff;
$darkblue: #252a41;
$verydarkhr: rgba(78, 85, 116, 0.3);
$darkhr: #4d5473;
$verydarktext : #4d5473;
$icontext: #777b91;
$darktext: #888da8;
$darktexthover: #9ca1bb;
$border: #afb1bd;