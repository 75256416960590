button {
    background: none;
    border: 0;
    box-sizing: border-box;
    cursor: pointer;
}
.button {
    display: inline-block;
    border-radius: 10em;
    padding: 1.15em 1.5em;
    user-select: none;
    @include media("<widephone") {
    }
    &.wide {
        padding: 1.15em 3em;
    }
    &.almost-wide {
        padding: 1.15em 2em;
    }
    background-color: #eee;
    text-decoration: none;
    font-weight: bold;
    font-size: 1em;
    line-height: 1.1;
    > .inside {
    }
    &.main {
        color: white;
        background: linear-gradient(90deg, rgba(251, 114, 64, 1) 10%, rgba(254, 200, 114, 1) 95%);
        text-shadow: 0 1px rgba(251, 114, 64, 1);
        box-shadow: 0px 0px 0.8em rgba(251, 115, 49, 0.25);
        transition: all 165ms ease;
        background-size: 120% auto;
        background-position: 0 0;
        outline: none;
        -webkit-tap-highlight-color: transparent;
        @include media(">=tablet") {
            &:hover {
                background-position: -1.7em 0em;
                box-shadow: 0px 0.15em 1.2em rgba(251, 115, 49, 0.5);
                transform: translateY(-0.1em);
            }
        }
        &:active {
            box-shadow: 0px 0px 0.3em rgba(251, 115, 49, 0.3);
            transform: translateY(0.05em);
            transition-duration: 70ms;
            background-position: 0em 0;
            @include media("<=tablet") {
                background-position: -1.7em 0em;
            }
        }
        &.circle-main {
            @include media(">=tablet") {
                &:hover {
                    background-position: -0.65em 0em;
                }
            }
            &:active {
                @include media("<=tablet") {
                    background-position: -0.65em 0em;
                }
            }
        }
    }
    &.m {
        font-size: 1.2em;
    }
    &.squared {
        border-radius: 0.1em;
    }
    &.hollow {
        background: transparent;
        border: $darkhr 1px solid;
        color: $white;
        min-width: 2em;
        text-align: center;
        // height: 3.5em;
        outline: none;
        transition: all 165ms ease;
        -webkit-tap-highlight-color: transparent;

        @include media(">=tablet") {
            &:hover {
                border-color: $icontext;
                transform: scale(1.07);
            }
        }
        &:active {
            border-color: $icontext;
            transform: scale(0.97);
            transition-duration: 70ms;
        }
        &.orange {
            border: $darkhr 0.1em solid;
            color: black;
            border-color: $orange;
        }
    }
    &.circle {
        padding: 0;
        min-width: 3.5em;    
    }
    &.linkalike {
        text-decoration: underline;
        font-weight: 400;
        background: none;
        opacity: 0.7;
    }
    &:disabled {
        background: #eee !important;
        color:#999 !important;
        box-shadow: none !important;
        text-shadow: none !important;
    }
}
