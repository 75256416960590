.bouncing-letters {
  $initial_wait: -3300ms;
  $duration: 4000ms;
  $delay_between_each: 75ms;
  span.letter {
    display: inline-block;
    transform-origin: center;
    @keyframes MoveUpDown2 {
      0% {
        transform: translateY(0em) rotate(0deg) scale(1);
      }
      80% {
        transform: translateY(0.05em) rotate(0deg) scale(1);
      }
      85% {
        transform: translateY(0.12em) rotate(-2deg) scale(0.95);
      }
      90% {
        transform: translateY(-0.15em) rotate(3deg) scale(1.1);
      }
      95% {
        transform: translateY(0em) rotate(-2deg) scale(0.95);
      }
      100% {
        transform: translateY(0em) rotate(0deg) scale(1);
      }
    }
    animation: MoveUpDown2 $duration ease-in-out infinite;
    @for $i from 0 through 40 {
      &:nth-child(#{$i}) {
        animation-delay: $initial_wait + $delay_between_each * $i;
      }
    }
  }
}
