.link-copied-wrap {
  position: relative;
  * {
    text-shadow: none;
  }
  span.link-copied {
    font-size: 1rem;
    @include media("<desktop") {
      font-size: 0.5rem;
    }
    pointer-events: none;
    display: block;
    position: absolute;
    left: 50%;
    bottom: 100%;
    transform: translateX(-50%);

    & > span.inside {
      animation: popupAndDie 2.5s ease forwards;
      transform-origin: bottom;
      @keyframes popupAndDie {
        0% {
          transform: scale(0.5) translateY(3em);
          opacity: 0;
        }
        15% {
          transform: scale(1) translateY(-1em);
          opacity: 1;
        }
        80% {
          transform: scale(1) translateY(-1em);
          opacity: 1;
        }
        100% {
          transform: scale(1) translateY(-2em);
          opacity: 0;
        }
      }

      padding: 1em 1.5em;
      white-space: nowrap;
      display: inline-block;
      background: $darkhr;
      // box-shadow: 0 0 1.5em -0.5em black;
      border-radius: 0.5em;
      color: $white;

      &:after {
        top: 100%;
        left: 50%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-color: rgba(255, 255, 255, 0);
        border-top-color: $darkhr;
        border-width: 0.7em;
        margin-left: -0.7em;
      }

      text-align: center;
    }
  }
}
