.fullheight {
    min-height: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-flow: column;
    align-items: stretch;
    justify-content: space-between;

    &.middle {
        justify-content: center;
        max-width: 100%;
    }

    .grow {
        flex-grow: 1;
    }

    .middle {
        display: inline-flex;
        flex-wrap: wrap;
        justify-content: center;
        align-content: center;
        flex-flow: column;
    }
}

.wrap {
    width: 80%;
    max-width: 960px;
    margin: 0 auto;

}

hr {
    border: 0;
    border-bottom: $darkhr 1px solid;
    margin: 1.2em 0;
}